<template>
  <div class="Home" id="Home" v-cloak>
    <div class="contents_wrap sectionnone" :class="{ sectionblock: isActive }">
      <div style="position: absolute" class="login_bg">
        <div class="lang" style="float: right">
          <ul>
            <li class="kor" @click="changeFlag('kr')">
              <a
                :class="{ active: langFlag == 'kr', off: langFlag == 'en' }"
              ></a>
            </li>
            <!--             style="background-image: url('../assets/icons/kor_off.png')"-->
            <li class="usa" @click="changeFlag('en')">
              <a
                :class="{ active: langFlag == 'en', off: langFlag == 'kr' }"
              ></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="login_wrap">
        <div class="login_tit">
          <h2>
            <i></i>
            {{ $t("lTitle") }}
          </h2>
        </div>
        <div class="login_cont">
          <div class="tab">
            <ul class="clearfix">
              <li>
                <a @click="goto()" href="javascript:void(0);">
                  {{ $t("examinee") }}</a
                >
              </li>
              <li>
                <a class="active" href="javascript:void(0);">{{
                  $t("institution")
                }}</a>
              </li>
            </ul>
          </div>
          <fieldset>
            <legend class="ir">아이디, 비밀번호 입력</legend>
            <input
              type="text"
              name="name"
              @keyup.enter="checkMail"
              :placeholder="$t('loginId')"
              v-model="email"
            />
            <div
              class="uk-inline"
              style="display: block"
              v-if="showPass == false"
            >
              <!--              <a class="uk-form-icon uk-form-icon-flip" href="javascript:void(0)"-->
              <!--                 uk-icon="icon: unlock; ratio: 1.5"-->
              <!--                 style="top: 11px;" @click="isPass()"></a>-->
              <img
                src="../assets/img/show.png"
                style="top: 17px; width: 25px; right: 10px; position: absolute"
                @click="isPass()"
              />
              <input
                class="uk-input"
                type="password"
                @keyup.enter="checkMail"
                :placeholder="$t('password')"
                v-model="password"
                style="background: #e8f0fe"
              />
            </div>
            <div class="uk-inline" style="display: block" v-else>
              <!--              <a class="uk-form-icon uk-form-icon-flip" href="javascript:void(0)"-->
              <!--                 uk-icon="icon: lock; ratio: 1.5"-->
              <!--                 style="top: 11px;" @click="isPass()"></a>-->
              <img
                src="../assets/img/hide.png"
                style="top: 17px; width: 25px; right: 10px; position: absolute"
                @click="isPass()"
              />
              <input
                class="uk-input"
                type="text"
                @keyup.enter="checkMail"
                :placeholder="$t('password')"
                v-model="password"
                style="background: #e8f0fe"
              />
            </div>
            <!--              placeholder="Password를 입력하세요."-->
            <div class="check_box clearfix">
              <input
                type="checkbox"
                id="check"
                name="remember"
                v-model="remember"
              />
              <label for="check">{{ $t("remMe") }}</label>
              <span class="error_txt">{{ error }}</span>
            </div>
            <a href="javascript:void(0);" class="bg_btn" @click="checkMail()">{{
              $t("login.login")
            }}</a>
          </fieldset>
          <!-- //아이디 비밀번호 영역 -->
          <!--           @click="forgotId()"-->
          <div class="btn_area clearfix">
            <!--              <a href="javascript:void(0);">회원가입</a>-->
            <!--              <a href="javascript:void(0);">비밀번호 찾기</a>-->
            <!--            <a href="javascript:void(0);">{{$t('login.forget')}}</a>-->
          </div>
          <fieldset v-show="forgot == true">
            <input
              type="email"
              name="email"
              @keyup.enter="forgotPass"
              v-model="resetPassEmail"
              placeholder="Email"
            />
            <a
              href="javascript:void(0);"
              class="bg_btn"
              @click="forgotPass()"
              style="background: #18259e"
              >Reset password</a
            >
          </fieldset>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import("../assets/css/reset.css");
import("../assets/css/style2.css");
import("../assets/css/common2.css");
import store from '@/store/store'
import AuthenticationService from "@/services/AuthenticationService";
import UserService from "@/services/UserService";

import UIkit from "uikit";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      email: "",
      password: "",
      error: null,
      resetPassEmail: "",
      orgaList: [],
      orgacheck: "",
      sendemail: "",
      signapng: "",
      signFlag: false,
      forgot: false,
      langFlag: "en",
      remember: "",
      option: {
        penColor: "rgb(13, 135, 249)",
        backgroundColor: "rgb(255,255,255)",
      },
      isActive: false,
      disabled: false,
      showPass: false,
      reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  created() {
    var _this = this;
    setTimeout(function () {
      _this.acs();
    }, 200);

    this.langFlag = this.$i18n.locale;
    this.checking()
  },
  methods: {
    checking(){
      if (store.getters.currentUser != null)
      {
        var auth = store.getters.currentUser.authorities[0].authority
        let lang = this.langFlag == "kr" ? "ko" : this.langFlag;
        if (auth == 'ROLE_USER')
        {
          // window.location.href = "http://localhost:8084/exam/student_home"
          if (window.location.host.includes('kr'))
          {
            window.location.href = '/ubtexam?lang=ko'
          }
          else{
            window.location.href = '/ubtexam?lang='+lang
          }
        }
        else if (auth == 'ROLE_PROCTOR')
        {
          this.$router.push({
            name: "OrganizationExamineeResultGrid2"
          });
        }
        else{
          if (window.location.host.includes('kr'))
          {
            window.location.href = "/kr/organization"
          }
          else{
            window.location.href = "/en/organization"
          }
        }
      }
    },
    goto() {
      let lang = this.langFlag == "kr" ? "ko" : this.langFlag;
      window.location.href = "/ubtexam?lang=" + lang;
    },
    changeFlag(item) {
      if (item == "kr") {
        this.$i18n.locale = "kr";
        this.langFlag = "kr"
      } else {
        this.$i18n.locale = "en";
        this.langFlag = "en";
      }
    },
    isPass() {
      this.showPass = !this.showPass;
    },
    acs() {
      this.isActive = true;
    },
    // checkUrl(){
    //     if (window.location.host.includes('kr'))
    //     {
    //         this.signFlag = true
    //     }
    // },
    isEmailValid: function () {
      return this.resetPassEmail == ""
        ? ""
        : this.reg.test(this.resetPassEmail)
        ? "uk-text-success"
        : "uk-text-danger";
    },
    orgaCheck(item) {
      this.orgacheck = "";
      this.orgacheck = item;
      this.sendemail = item + this.email;
      this.checkMail();
      UIkit.modal("#signin").hide();
    },
    async forgotPass() {
      try {
        if (this.resetPassEmail != "") {
          const response = await AuthenticationService.loginReset({
            email: this.resetPassEmail,
          });
          this.forgot = false;
          if (response.data.status == 200) {
            if (response.data.success == true) {
              alert(response.data.message);
            }
          }
        } else {
          alert("please write your email.");
        }
      } catch (error) {
        this.error = error.response.data.message;
      }
    },
    forgotId() {
      this.forgot = true;
    },
    async checkMail() {
      if (this.email.includes("@")) {
        this.login();
      } else {
        try {
          const response = await AuthenticationService.idCheck({
            uid: this.email,
          });
          if (response.data.status == 222) {
            if (response.data.success == true) {
              if (response.data.result.orgs.length == 1) {
                this.sendemail =
                  response.data.result.orgs[0].organiz_code + this.email;
                this.login();
              } else {
                this.orgaList = response.data.result.orgs;
                UIkit.modal("#signin").show();
              }
            }
          }
          if (response.data.status == 200) {
            if (response.data.success == true) {
              if (response.data.result == null) {
                // this.sendemail == ''
                this.login();
              }
            }
          } else {
            if (response.data.result == null) {
              this.error = "User not found with username or email.";
              this.email = "";
              this.password = "";
            } else {
              this.error = "User not found with username or email.";
              this.email = "";
              this.password = "";
            }
            // console.log("we can do it")
            UIkit.modal("#signin").hide();
            this.orgacheck = "";
            this.password = "";
          }
        } catch (error) {
          this.error = error.response.data.message;
        }
      }
    },
    async login() {
      try {
        if (this.sendemail == "") {
          this.sendemail = this.email;
        }
        if (this.signFlag == true) {
          this.save();
          if (this.$refs.qcanvas.isEmpty()) {
            UIkit.modal("#signTow").show();
            return;
          }
        }
        const domes = await AuthenticationService.login({
          usernameOrEmail: this.sendemail,
          password: this.password,
          type: "admin",
          sign: this.signapng,
        });
        if (domes.status == 333) {
          alert(domes.message);
        }
        if (domes.status == 200) {
          this.$store.dispatch("setToken", domes.data.accessToken);
          this.$setCookie("utoken", domes.data.accessToken);
          var sto;
          if (typeof window.browserStorage !== "undefined") {
            sto = window.browserStorage;
          } else {
            sto = localStorage;
          }
          sto.setItem("utoken", domes.data.accessToken);
          this.me();
        }
      } catch (error) {
        this.error = error.response.data.message;
        this.sendemail = "";
        UIkit.modal("#signin").show();
        this.orgacheck = "";
        this.password = "";
      }
    },
    register() {
      this.$router.push({
        name: "register",
      });
    },
    async me() {
      try {
        const response = await UserService.me();
        this.$store.dispatch("setUser", response.data);
        this.$setCookie("studentid", response.data.id);
        this.$setCookie("loginId", response.data.username);
        this.$setCookie("urole", response.data.authorities[0].authority);
        this.$setCookie("uorganizId", response.data.organizs[0].organizId);
        var sto;
        if (typeof window.browserStorage !== "undefined") {
          sto = window.browserStorage;
        } else {
          sto = localStorage;
        }
        sto.setItem("studentid", response.data.id);
        sto.setItem("loginId", response.data.username);
        sto.setItem("urole", response.data.authorities[0].authority);
        sto.setItem("uorganizId", response.data.organizs[0].organizId);

        var d = new Date();
        var exdays = 1;
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        var expires = "expires=" + d.toUTCString();

        document.cookie =
          "studentid=" + response.data.id + ";" + expires + ";path=/";
        document.cookie =
          "loginId=" + response.data.username + ";" + expires + ";path=/";
        document.cookie =
          "urole=" +
          response.data.authorities[0].authority +
          ";" +
          expires +
          ";path=/";
        document.cookie =
          "uorganizId=" +
          response.data.organizs[0].organizId +
          ";" +
          expires +
          ";path=/";

        response.data.authorities.forEach((item) => {
          if (item.authority === "ROLE_USER") {
            this.$router.push({
              name: "student",
            });
            // window.location.href = '/ubtexam/student_home'
            // window.location.href = 'http://localhost:8084/exam/student_home'
          }
          if (item.authority === "ROLE_PROCTOR") {
            this.$router.push({
              // name: "OrganizationExamineeResultGrid2"
              name: "OrganizationProcExamList",
            });
          } else {
            this.$router.push({
              name: "organization",
            });
          }
        });
      } catch (error) {
        this.error = error;
      }
    },
    save() {
      var _this = this;
      this.signapng = _this.$refs.qcanvas.save();
      console.log(this.signapng);
    },
    clear() {
      var _this = this;
      _this.$refs.qcanvas.clear();
    },
    undo() {
      var _this = this;
      _this.$refs.qcanvas.undo();
    },
  },
};
</script>

<style scoped>
[v-cloak] {
  display: none;
}
.sectionnone {
  display: none;
}
.sectionblock {
  display: block;
}
input {
  height: 36px;
}
input,
input::placeholder {
  font-size: 18px !important;
}

.lang > ul {
  background: #fff;
  padding: 5px 12px;
  margin-top: 30px;
  border-radius: 5px;
  margin-right: 50px;
}

.lang ul li.kor .active {
  background-image: url("../assets/icons/kor_on.png") !important;
}

.lang ul li.usa .active {
  background-image: url("../assets/icons/usa_on.png") !important;
}

.lang ul li.kor .off {
  background-image: url("../assets/icons/kor_off.png") !important;
}

.lang ul li.usa .off {
  background-image: url("../assets/icons/usa_off.png") !important;
}

.lang ul li.kor a {
  background-position: left;
}

.lang ul li.usa a {
  background-position: right;
}

.lang ul li {
  display: table-cell;
}

.lang ul li a {
  display: block;
  background: #fff;
  width: 75px;
  height: 25px;
  background-repeat: no-repeat;
}

.passGrp img {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  width: 25px;
  padding-top: 6px;
  cursor: pointer;
}

.login_tit h2 {
  font-size: 14px;
  line-height: 70px;
  color: #a6d2fe;
  font-weight: 700;
  transform: skew(-0.5deg);
}
</style>
